

























import { Vue, Component } from 'vue-property-decorator';
import ApprovedIcon from '@/assets/icon/ico-approved.svg';
import OerIcon from '@/assets/icon/ico-oer.svg';

import {createHelpers} from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'getSearchFiltersField',
  mutationType: 'updateSearchFiltersField',
});

@Component({
  components: {ApprovedIcon, OerIcon},
  computed: {
    ...mapFields([
      'recommended',
      'oer'
    ])
  }
})
export default class SearchFilterControls extends Vue {
  public recommended!: boolean;
  public oer!: boolean;

  public action(): void {
    this.$store.dispatch('onChangeFilter');
  }
}
