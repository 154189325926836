





































import { Vue, Component } from 'vue-property-decorator';
import {AdaptiveMode} from '@/store/modules/ui/ui.store';
import {createHelpers} from 'vuex-map-fields';

import SearchFilterTitle from '@/pages/Search/Search.filter.title.vue';
import SearchFilterTabs from '@/pages/Search/Search.filter.tabs.vue';

import ResetIcon from '@/assets/icon/ico-reset.svg';
import CheckDoneIcon from '@/assets/icon/ico-check-done.svg';

const { mapFields } = createHelpers({
  getterType: 'getSearchField',
  mutationType: 'updateSearchField',
});

@Component({
  components: {
    SearchFilterTitle,
    SearchFilterTabs,
    ResetIcon,
    CheckDoneIcon
  },
  computed: {
    ...mapFields([
      'isActiveFilterTab'
    ])
  }
})

export default class SearchFilterModal extends Vue {
  private ResetIcon = ResetIcon;
  private CheckDoneIcon = CheckDoneIcon;
  private AdaptiveMode = AdaptiveMode;
  private isActiveFilterTab!: boolean;
  public actionName: string | null = null;

  public setActionName(name: string) {
    this.actionName = name;
  }

  public action() {
    if (!this.actionName) return;
    this.$store.dispatch(`${this.actionName}`);
  }

  public reset() {
    this.$store.dispatch('resetFilters');
  }

  public openModal(): void {
    this.isActiveFilterTab = true;
  }

  public closeModal(): void {
    this.isActiveFilterTab = false;
  }
}
