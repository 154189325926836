










































































































































import { Vue, Component } from 'vue-property-decorator';
import {FilterDictionary, IFilterData, SearchFilterType} from '@/services/api/search.service';
import SearchFilterControl from '@/pages/Search/Search.filterTab.media.control.vue';
import SearchFilterTabActions from '@/pages/Search/Search.filterTab.actions.vue';
import AccordionItem from '@/components/UI/Accordion/AccordionItem.vue';

import {createHelpers} from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'getSearchMediaFiltersField',
  mutationType: 'updateSearchMediaFiltersField',
});

@Component({
  components: {
    SearchFilterControl,
    SearchFilterTabActions,
    AccordionItem
  },
  computed: {
    ...mapFields([
      'subject',
      'targetGroup',
      'mediaType',
      'loanable'
    ])
  }
})
export default class SearchMediaFilterTab extends Vue {
  private subject!: IFilterData[];
  private targetGroup!: IFilterData[];
  private mediaType!: IFilterData[];
  private loanable!: IFilterData[];

  private titleDictionary: FilterDictionary<string> = {
    targetGroup: 'Zielgruppe',
    subject: 'Fächer',
    mediaType: 'Online-Medien',
    loanable: 'Zum Ausleihen'
  };

  private filters: FilterDictionary<IFilterData[]> = {
    subject: [],
    targetGroup: [],
    mediaType: [],
    loanable: []
  }

  private created(): void {
    this.filters = {
      subject: this.subject,
      targetGroup: this.targetGroup,
      mediaType: this.mediaType,
      loanable: this.loanable
    };
  }

  private get filterListLimit(): number {
    return this.$store.getters.isMobileDeviceAdaptiveMode ? 6 : 9;
  }

  private mobileTitle(name: SearchFilterType): string {
    const filtered = this.filters[name].filter(item => item.value);
    return filtered.length > 0 
      ? `${this.titleDictionary[name]} (${filtered.length})`
      : this.titleDictionary[name];
  }

  private get mediaCollectionFilters(): IFilterData[] {
    return this.filters['mediaType']
      .filter(item => !(item.facetScore <= 0 && !item.value)
        && (item.name === 'Online-Mediensammlung' 
        || item.name === 'Unterrichtsmodul'
        || item.name === 'DVD-Video'));
  }

   private get mediaSingleFilters(): IFilterData[] {
    return this.filters['mediaType']
      .filter(item => !(item.facetScore <= 0 && !item.value)
        && item.name !== 'Online-Mediensammlung' 
        && item.name !== 'Unterrichtsmodul'
        && item.name !== 'DVD-Video');
  }

  private action(): void {
    this.$store.dispatch('onChangeMediaFilter');
  }
}
