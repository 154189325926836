













import { Vue, Component } from 'vue-property-decorator';

import SearchFilterTitle from '@/pages/Search/Search.filter.title.vue';
import SearchFilterTabs from '@/pages/Search/Search.filter.tabs.vue';
import SearchFilterControls from '@/pages/Search/Search.filter.controls.vue';
import SearchFilterActions from '@/pages/Search/Search.filter.actions.vue';
import SearchFilterModal from '@/pages/Search/Search.filter.modal.vue';

@Component({
  components: {
    SearchFilterTitle,
    SearchFilterTabs,
    SearchFilterControls,
    SearchFilterActions,
    SearchFilterModal
  }
})
export default class SearchFilter extends Vue {}
