import FaqPerson from '@/assets/icon/faq-person.svg';
import FaqDownload from '@/assets/icon/faq-download.svg';
import FaqPersonPlus from '@/assets/icon/faq-person-plus.svg';

export interface IFaq { 
    icon: object;
    text: string;
}

export interface IFaqService { 
    getFaqList: () => IFaq[];
}

const getFaqList = () => [
    {
        icon: FaqPerson,
        text: 'Mein Zugang',
        link: 'https://www.lmz-bw.de/index.php?id=12419#c30593'
    },
    {
        icon: FaqDownload,
        text: 'Medien nutzen',
        link: 'https://www.lmz-bw.de/index.php?id=12419#c64463'
    },
    {
        icon: FaqPersonPlus,
        text: 'Medien freigeben',
        link: 'https://www.lmz-bw.de/index.php?id=12419#c64465'
    }
];

const FaqService :IFaqService = { 
    getFaqList
};

export default FaqService;
