




























import {Vue, Component, Prop} from 'vue-property-decorator';
import {IEducationalStandard} from '@/services/api/educational-standarts.service';

@Component({
  components: { SearchEducationalStandardsControlList: () => import('./Search.filterTab.educationalStandards.controlList.vue') }
})
export default class SearchEducationalStandardsControl extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public filter!: IEducationalStandard;

  @Prop({
    validator: v => ['object', 'number'].includes(typeof v),
    required: false,
    default: null
  })
  public radioModel!: IEducationalStandard;

  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  public checkboxModel!: null | number;

  private change(filter: IEducationalStandard): void {
    this.$emit('change', filter);
  }
}
