

















import {Vue, Component, Prop} from 'vue-property-decorator';
import {IEducationalStandard} from '@/services/api/educational-standarts.service';
import SearchEducationalStandardsControl from './Search.filterTab.educationalStandards.control.vue';

@Component({
  components: {
    SearchEducationalStandardsControl
  }
})
export default class SearchEducationalStandardsControlList extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  public id!: number;
  
  private radioModel: null | number = this.filterList
    .reduce(
      (result: null | number, item: IEducationalStandard) => {
        if (result === null && this.$store.getters.selectedEducationalStandardFilters.includes(item.id)) {
          result = item.id;
        }
        return result;
      },
      null
    );
  private checkboxModel: number[] = this.filterList
    .reduce(
      (result: number[], item: IEducationalStandard) => {
        if (this.$store.getters.selectedEducationalStandardFilters.includes(item.id)) {
          result.push(item.id);
        }
        return result;
      },
      []
    );;

  private get filterList(): IEducationalStandard[] {
    return this.$store.getters.getEducationalStandardFilterList(this.id);
  }
  
  private change(filter: IEducationalStandard): void {
    const {level} = filter;
    if (this.$store.getters.getEducationalStandardFilterList(filter.id).length) {
      if (this.radioModel === filter.id) {
        this.radioModel = null;
      } else {
        this.radioModel = filter.id;
      }
      this.$store.dispatch(
        'setSelectedEducationalStandards',
        {level, data: [this.radioModel]}
      );
    } else {
      if (this.checkboxModel.includes(filter.id)) {
        this.checkboxModel = this.checkboxModel.filter((value: number) => value !== filter.id);
      } else {
        this.checkboxModel.push(filter.id);
      }
      this.$store.dispatch(
        'setSelectedEducationalStandards',
        {level, data: this.checkboxModel}
      );
    }
  }
}
