












































import { Vue, Component } from 'vue-property-decorator';
import { SearchSortType} from '@/services/api/search.service';
import SortDropdown from '@/components/UI/SortDropdown.vue';

import SortIcon from '@/assets/icon/ico-sort.svg';
import CheckDoneIcon from '@/assets/icon/ico-check-done.svg';

import {createHelpers} from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'getSearchField',
  mutationType: 'updateSearchField'
});

@Component({
  components: {
    SortDropdown,
    CheckDoneIcon,
    SortIcon
  },
  computed: {
    ...mapFields([
      'sort'
    ])
  }
})

export default class SearchFilterSort extends Vue {
  private sort!: SearchSortType;
  private SortIcon = SortIcon;
  private CheckDoneIcon = CheckDoneIcon;
  private modalVisible: boolean = false;

  private sortFields: Record<SearchSortType, string> = {
    RELEVANCE: 'Relevanz',
    PRODUCTION_YEAR_ASC: 'Produktionsjahr',
    PRODUCTION_YEAR: 'Produktionsjahr',
    TITLE_ASC: 'Alphabetisch: A-Z',
    TITLE_DESC: 'Alphabetisch: Z-A',
  };

  private sortHandler(value: SearchSortType): void {
    this.closeModal();
    this.$store.dispatch('onChangeSort', value);
  }

  public openModal(): void {
    this.modalVisible = true;
  }
  public closeModal(): void {
    this.modalVisible = false;
  }
}
