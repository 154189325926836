
























// TODO create dropdown button component with template
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IDictionary} from '@/interfaces/interfaces';
import clickOutside from '@/directives/click-outside';

@Component({
  directives: {clickOutside},
})
export default class SortDropdown extends Vue {
  private show: boolean = false;

  @Prop({
    type: String,
    required: true,
    default: null
  })
  public title!: string;

  @Prop({
    type: Object,
    required: false
  })
  public icon!: string;

  @Prop({
    type: String,
    required: false,
    default: 'primary'
  })
  public mode!: string;

  @Prop({
    type: String,
    required: false,
    default: 'sm'
  })
  public size!: string;

  @Prop({
    type: String,
    required: false,
    default: null
  })
  public active!: null | string;

  @Prop({
    type: Object,
    required: true
  })
  public listItem!: IDictionary<string>;

  public created(): void {
    window.addEventListener('click', this.close);
  }

  public beforeDestroy(): void {
    window.removeEventListener('click', this.close);
  }

  public choose(value: string): void {
    this.$emit('choose', value);
    this.show = !this.show;
  }

  private close(event: Event): void {
    if (!this.$el.contains(event.target as Node)) {
      this.show = false;
    }
  }
}
