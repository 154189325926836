



























import {Vue, Prop, Component} from 'vue-property-decorator';

// @ts-ignore
import Paginate from 'vuejs-paginate';
import Chevron from '@/components/UI/Icons/Chevron.vue';

@Component({
  components: {
    Chevron,
    Paginate
  }
})
export default class Pager extends Vue {
  private prev: string = '';
  private next: string = '';

  @Prop({
    type: Number,
    required: false,
    default: 1
  })
  private page!: number;

  @Prop({
    type: Number,
    required: true,
    default: false
  })
  private totalPages!: number;

  private setPage(page: number): void {
    this.$emit('action', page);
  }

  private mounted() {
    const prev = this.$refs.prevContent as Vue;
    const next = this.$refs.nextContent as Vue;

    this.prev = prev.$el.outerHTML;
    this.next = next.$el.outerHTML;
  }
}
