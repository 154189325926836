




















import { Vue, Component } from 'vue-property-decorator';
import clickOutside from '@/directives/click-outside';
import SearchMediaFilterTab from '@/pages/Search/Search.filterTab.media.vue';
import SearchEducationalStandardsFilterTab from '@/pages/Search/Search.filterTab.educationalStandards.vue';

import {createHelpers} from 'vuex-map-fields';
import {SearchFilter} from '@/store/modules/search.store';
const { mapFields } = createHelpers({
  getterType: 'getSearchField',
  mutationType: 'updateSearchField',
});

@Component({
  components: {
    SearchMediaFilterTab,
    SearchEducationalStandardsFilterTab
  },
  directives: {
    clickOutside
  },
  computed: {
    ...mapFields([
      'isActiveFilterTab',
      'appliedFilter'
    ])
  }
})
export default class SearchFilterTabs extends Vue {
  private isActiveFilterTab!: boolean;
  private isInitialized: boolean = false;
  private appliedFilter!: null | SearchFilter;

  private close(): void {
    if (this.$store.getters.isMobileDeviceAdaptiveMode) return;
    if (this.isInitialized) {
      this.isActiveFilterTab = false;
    } else {
      this.isInitialized = true;
    }
  }

  private get activeTab(): any {
    return this.appliedFilter === 'educational-standard' ? 1 : 0;
  }
}
