


















import { VueConstructor } from 'vue';
import { Vue, Component } from 'vue-property-decorator';

import { ButtonMode } from '@/interfaces/interfaces';
import SearchFilterSort from '@/pages/Search/Search.filter.sort.vue';

import FilterIcon from '@/assets/icon/ico-filter.svg';
import CloseIcon from '@/assets/icon/ico-close.svg';
import ResetIcon from '@/assets/icon/ico-reset.svg';

import {createHelpers} from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'getSearchField',
  mutationType: 'updateSearchField',
});

@Component({
  components: { SearchFilterSort, ResetIcon },
  computed: {
    ...mapFields([
      'isActiveFilterTab'
    ])
  }
})

export default class SearchFilterActions extends Vue {
  private ResetIcon = ResetIcon;
  public isActiveFilterTab!: boolean;

  private get mode(): ButtonMode {
    return this.isActiveFilterTab && !this.$store.getters.isMobileDeviceAdaptiveMode ? 'primary' : 'secondary';
  }

  private get icon(): VueConstructor<Vue> {
    return this.isActiveFilterTab && !this.$store.getters.isMobileDeviceAdaptiveMode ? CloseIcon : FilterIcon;
  }

  private toggleFilters(): void {
    this.$store.getters.isMobileDeviceAdaptiveMode
      ? this.$emit('openModal')
      : this.isActiveFilterTab = !this.isActiveFilterTab;
  }
}
