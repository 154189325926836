





























import { Vue, Component} from 'vue-property-decorator';
import SectionHeader from '@/pages/Main/Section.header.vue';
import FaqService from '@/services/data/faq.service';
import Slider from '@/components/Slider/Slider.vue';

@Component({
  components: { SectionHeader, Slider }
})
export default class Faq extends Vue {
  faqList = FaqService.getFaqList();
}
