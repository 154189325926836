










import { Vue, Component, Prop } from 'vue-property-decorator';
import {IFilterData} from '@/services/api/search.service';

@Component
export default class SearchFilterControl extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public filter!: IFilterData;
}
