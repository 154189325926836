





















import Vue from 'vue';
import Component from 'vue-class-component';
import {scrollTo} from '@/utilities';
import SearchFilter from '@/pages/Search/Search.filter.section.vue';
import Card from '@/components/UI/Card/Card.vue';
import Pager from '@/components/UI/Pager/Pager.vue';
import FAQ from '@/pages/Main/Faq.section.vue';

@Component({
  components: {
    SearchFilter,
    Card,
    Pager,
    FAQ
  },
  beforeRouteUpdate(to, from, next) {
    if (to.hash || from.hash) return next();
    this.$store.dispatch(
      'parseSearchParameters',
      to.query
    )
      .then(() => next());
  },
})
export default class Search extends Vue {

  public created(): void {
    this.$store.dispatch(
      'parseSearchParameters',
      this.$route.query.sid 
        ? Object.keys(this.$route.query).length === 1
          ? {...this.$route.query, ltiInitial: true}
          : this.$route.query
        : this.$route.query
    );
  }

  public navigate(page: number): void {
    this.$store.dispatch('onChangePage', page - 1)
      .then(() => scrollTo('.app-container', 'smooth'));
  }
}
