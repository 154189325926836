



















































































import { Vue, Component } from 'vue-property-decorator';

import {IEducationalStandard} from '@/services/api/educational-standarts.service';
import {IFilterData} from '@/services/api/search.service';
import EducationalStandardsSelect from '@/components/Layout/Header/EducationalStandardsSelect.vue';
import SearchFilterControl from '@/pages/Search/Search.filterTab.media.control.vue';
import SearchEducationalStandardsControlList from './Search.filterTab.educationalStandards.controlList.vue';
import SearchFilterTabActions from '@/pages/Search/Search.filterTab.actions.vue';
import AccordionItem from '@/components/UI/Accordion/AccordionItem.vue';

import {createHelpers} from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'getSearchEducationalStandardsField',
  mutationType: 'updateSearchEducationalStandardsField',
});

@Component({
  components: {
    EducationalStandardsSelect,
    SearchFilterControl,
    SearchEducationalStandardsControlList,
    SearchFilterTabActions,
    AccordionItem
  },
  computed: {
    ...mapFields([
      'aspect',
      'schoolType',
      'subject',
      'classroom',
    ])
  }
})

export default class SearchEducationalStandardsFilterTab extends Vue {
  private aspect!: IFilterData[];
  private schoolType!: null | IEducationalStandard;
  private subject!: null | IEducationalStandard;
  private classroom!: null | IEducationalStandard;

  private get isShowEducationalStandardsFilter(): boolean {
    return !!this.classroom && !!this.$store.getters.getEducationalStandardFilterList(this.classroom.id).length;
  }

  private checkSchoolType(): void {
    if (!this.$store.getters.schoolTypeFilterList.length) {
      this.$store.dispatch('getSchoolTypeFilterList');
    }
  }
  private setSchoolType(value: IEducationalStandard): void {
    this.$store.dispatch('setSchoolTypeFilter', value);
  }

  private checkSubject(): void {
    if (!this.$store.getters.subjectFilterList.length) {
      this.$store.dispatch('getSubjectFilterList');
    }
  }
  private setSubject(value: IEducationalStandard): void {
    this.$store.dispatch('setSubjectFilter', value);
  }

  private checkClassroom(): void {
    if (!this.$store.getters.classroomFilterList.length) {
      this.$store.dispatch('getClassroomFilterList');
    }
  }
  private setClassroom(value: IEducationalStandard): void {
    this.$store.dispatch('setClassroomFilter', value);
  }

  private action(): void {
    this.$store.dispatch('onChangeEducationalStandardsFilter');
  }

  private aspectMobileTitle(): string {
    const filtered = this.aspect.filter(item => item.value);
    return filtered.length > 0 
      ? `Kompetenzfelder Medienbildung (${filtered.length})`
      : 'Kompetenzfelder Medienbildung';
  }
}
