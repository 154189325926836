
















import {Vue, Component, Prop} from 'vue-property-decorator';
import ResetIcon from '@/assets/icon/ico-reset.svg';

@Component({
  components: { ResetIcon }
})
export default class SearchFilterTabActions extends Vue {
  public ResetIcon = ResetIcon;

  @Prop({
    type: Function,
    required: true
  })
  public action!: () => void;

  private onAction(): void {
    this.action();
  }

  private onResetFilters(): void {
    this.$store.dispatch('resetFilters');
  }
}
